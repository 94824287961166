import React, { useState, useEffect, useMemo } from 'react';
import * as S from './StagingIndicator.styles';

const StagingIndicator = () => {
  const [checkoutUrl, setCheckoutUrl] = useState(
    process.env.GATSBY_CHECKOUT_API_URL_STAGING
  );
  const [init, setInit] = useState(false);
  const [selected, setSelected] = useState(0);

  const stagingCheckoutUrls = useMemo(
    () =>
      process.env.GATSBY_FUNNEL_STAGING_URLS?.split('|') || [
        process.env.GATSBY_CHECKOUT_API_URL_STAGING
      ],
    []
  );

  useEffect(() => {
    if (!init) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      if (params) {
        setSelected(parseInt(params.cko || 0));
        setCheckoutUrl(stagingCheckoutUrls[params.cko || 0]);
      }
      setInit(true);
    }
  }, [setCheckoutUrl, checkoutUrl, stagingCheckoutUrls, init, setInit]);

  const handleOnChange = evt => {
    const index = evt.target.value;
    setSelected(parseInt(index));
    window.location.href = `${window.location.pathname}?cko=${index}`;
  };

  return (
    <S.Container>
      <strong>Staging Funnel</strong>
      <S.Select onChange={handleOnChange} value={selected}>
        {stagingCheckoutUrls.map((url, index) => (
          <option key={index} value={index}>
            {url}
          </option>
        ))}
      </S.Select>
    </S.Container>
  );
};

export default StagingIndicator;
