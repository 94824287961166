import { calculateDiscount } from './calculateDiscount';

const eventActionSchema = 'iglu:com.thepetlabco/event_action/jsonschema/3-0-0';
const appContextSchema = 'iglu:com.thepetlabco/app_context/jsonschema/1-0-0';
const productContextSchema =
  'iglu:com.thepetlabco/product_context/jsonschema/1-0-5';
const offerContextSchema =
  'iglu:com.thepetlabco/offer_context/jsonschema/1-0-0';
const discountCodeContextSchema =
  'iglu:com.thepetlabco/discount_code_context/jsonschema/1-0-1';

export const userViewEvent = (object = 'page') => {
  return {
    schema: eventActionSchema,
    data: {
      subject: 'user',
      action: 'viewed',
      object: object,
      categories: ['funnels']
    }
  };
};

export const UserViewedPage = ({ subscription, onetime, microdata }) => {
  const countryCode = onetime?.products?.length
    ? onetime?.products[0]?.checkoutData?.country
    : onetime?.products?.length
    ? subscription?.products[0]?.checkoutData?.country
    : 'US';
  const context = [
    {
      schema: appContextSchema,
      data: {
        app_name: `Funnels-${countryCode}`,
        app_environment:
          process.env.GATSBY_BUILDER_ENVIRONMENT === 'staging'
            ? 'staging'
            : 'production'
      }
    },
    {
      schema: productContextSchema,
      data: {
        ...(onetime?.products?.length
          ? {
              product_id: onetime?.products[0]?.checkoutData?.product_id
            }
          : {}),
        ...(subscription?.products?.length
          ? {
              product_sub_id:
                subscription?.products[0]?.checkoutData?.product_id
            }
          : {}),
        ...(onetime?.products?.length
          ? {
              product_name: onetime?.products[0]?.checkoutData?.title
            }
          : subscription?.products?.length
          ? {
              product_name: subscription?.products[0]?.checkoutData?.title
            }
          : {}),
        sku: microdata?.value?.data?.sku,
        ...(onetime?.products?.length
          ? {
              price: onetime?.products[0]?.checkoutData?.perceived_rrp
            }
          : subscription?.products
          ? {
              price: subscription?.products[0]?.checkoutData?.perceived_rrp
            }
          : {}),
        ...(onetime?.products?.length
          ? {
              variant_id: onetime?.products[0]?.checkoutData?.variant_id
            }
          : {}),
        ...(subscription?.products?.length
          ? {
              variant_sub_id:
                subscription?.products[0]?.checkoutData?.variant_id
            }
          : {})
      }
    }
  ];

  onetime?.bumpoffers?.forEach((item, i) => {
    if (item?.display_title === 'Bump Offer Title Here') {
      return;
    }

    context.push({
      schema: offerContextSchema,
      data: {
        title: item?.display_title,
        variant_id: item && item?.checkoutData?.variant_id,
        is_subscription_offer: false
      }
    });
  });

  subscription?.bumpoffers?.forEach((item, i) => {
    if (item?.display_title === 'Bump Offer Title Here') {
      return;
    }

    context.push({
      schema: offerContextSchema,
      data: {
        title: item?.display_title,
        variant_id: item && item?.checkoutData?.variant_id,
        is_subscription_offer: true
      }
    });
  });

  onetime?.discounts?.forEach(item => {
    const discount_percentage = calculateDiscount(
      onetime?.products[0]?.checkoutData?.price,
      onetime?.products[0]?.checkoutData?.discounted_price
    );

    discount_percentage > 0 &&
      context.push({
        schema: discountCodeContextSchema,
        data: {
          discount_code: item,
          discount_percentage,
          discount_amount: (
            onetime?.products[0]?.checkoutData?.price -
            onetime?.products[0]?.checkoutData?.discounted_price
          ).toFixed(2),
          is_subscription_code: false
        }
      });
  });

  subscription?.discounts?.forEach(item => {
    const discount_percentage = calculateDiscount(
      subscription?.products[0]?.checkoutData?.price,
      subscription?.products[0]?.checkoutData?.discounted_price
    );

    discount_percentage > 0 &&
      context.push({
        schema: discountCodeContextSchema,
        data: {
          discount_code: item,
          discount_percentage: discount_percentage,
          discount_amount: (
            subscription?.products[0]?.checkoutData?.price -
            subscription?.products[0]?.checkoutData?.discounted_price
          ).toFixed(2),
          is_subscription_code: true
        }
      });
  });

  return { context };
};
