import { useEffect } from 'react';
import axios from 'axios';

const IS_DEV = process.env.NODE_ENV === 'development';

export function useRedirect(
  geoRedirection,
  redirectToEcomm = false,
  redirectToEcommUrl
) {
  useEffect(() => {
    const redirectEcom = async () => {
      if (redirectToEcomm) {
        window.location.href =
          (redirectToEcommUrl ??
            process.env.GATSBY_ECOM_URL ??
            'https://thepetlabco.com/') + window.location.search;
      }
    };

    const getGeo = async () => {
      const results = await axios.get(`/.netlify/functions/geo-location`);

      let geoRedirectUrl;
      await geoRedirection.forEach(({ countryCode, url }) => {
        if (countryCode === results.data.countryCode) {
          geoRedirectUrl = url;
        }
      });

      if (geoRedirectUrl) {
        window.location.href = geoRedirectUrl;
      } else {
        await redirectEcom();
      }
    };

    if (geoRedirection && !IS_DEV) {
      void getGeo();
    } else {
      void redirectEcom();
    }
  }, [redirectToEcomm, redirectToEcommUrl, geoRedirection]);
}
