import { useEffect } from 'react';

export function useProductSelection(contextFunnel) {
  const {
    setTubIndex,
    setCurrentTubIndex,
    setCurrentCategory,
    setCurrentProduct
  } = contextFunnel;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const selection = parseInt(urlParams.get('sel'));
    const options = [1, 2, 3, 4, 5, 6, 7, 8];
    if (selection && options.includes(selection)) {
      const selTubIndex =
        selection <= 4 ? parseInt(selection - 1) : parseInt(selection - 5);
      const selCategory = selection <= 4 ? 'subscriptions' : 'onetime';
      const selProduct =
        selection <= 4
          ? `subscription_${parseInt(selection - 1)}`
          : `onetime_${parseInt(selection - 5)}`;
      setTubIndex(selTubIndex);
      setCurrentTubIndex(selTubIndex);
      setCurrentCategory(selCategory);
      setCurrentProduct(selProduct);
    }
  }, [setTubIndex, setCurrentTubIndex, setCurrentCategory, setCurrentProduct]);
}
