import React, { useContext, useEffect } from 'react';
import loadable from '@loadable/component';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import { addDataLayer } from '../../utils/dataLayer';

const Helmet = loadable(() => import('react-helmet'));

const Microdata = ({ path }) => {
  const {
    currency,
    currentCategory,
    currentProduct,
    funnelUrl = `${process.env.GATSBY_FUNNEL_ROOT_URL_LIVE}${path}`,
    microdata
  } = useContext(FunnelContext);

  useEffect(() => {
    if (microdata?.value?.data?.sku) {
      addDataLayer('product_viewed_event', {
        action: 'product_viewed_funnels',
        label: `${microdata.value.data.sku}`
      });
    }
  }, [microdata]);

  if (!microdata?.value) return null;

  const currencyCode = currency?.code || 'USD';

  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    brand: {
      '@type': 'Brand',
      name: 'PetLab Co.'
    },
    ...microdata.value.data
  };

  Object.assign(schema, {
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/NewCondition',
      price: discountedPrice,
      priceCurrency: currencyCode,
      url: funnelUrl,
      priceValidUntil: `${new Date().getFullYear()}-12-31`
    }
  });

  const aggregateRating = microdata.value.data?.aggregateRating;
  Object.assign(schema, {
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: aggregateRating?.ratingValue || 4.4,
      reviewCount: aggregateRating?.reviewCount || 7583
    }
  });

  const review = microdata.value.data?.review;
  Object.assign(schema, {
    review: {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review?.ratingValue || 4,
        bestRating: review?.bestRating || 5
      },
      author: {
        '@type': 'Person',
        name: review?.authorName || 'Kim Melrose'
      }
    }
  });

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default Microdata;
