import { useEffect } from 'react';
import { addDataLayer } from '../utils/dataLayer';
import { UserViewedPage, userViewEvent } from '../utils/eventTracking';

export function useViewTracking(
  currentCategory = 'subscriptions',
  subscription = {},
  onetime = {},
  microdata = {}
) {
  useEffect(() => {
    addDataLayer(
      userViewEvent('page'),
      UserViewedPage({ subscription, onetime, microdata })
    );
  }, [currentCategory, subscription, onetime, microdata]);
}
