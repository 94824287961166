import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  color: red;
  border: 1px solid red;
  box-shadow: 0 0 5px #ccc;
  z-index: 99999;
`;

export const Url = styled.div`
  font-size: 10px;
`;

export const Select = styled.select`
  font-size: 10px;
  display: block;
`;
